import React from "react"
import RangeSlider from "../RangeSlider"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as searchActions from "actions/searchActions"
import { Link } from "react-router-dom"
import { kr, kvm, plus } from "utils"

// Field Config 24 24 24
const SIZE_RANGE_CONFIG = {
  label: "Bolig areal m2",
  fieldName: "sizeRange",
  min: 1,
  max: 200,
  formatValue: plus(kvm, 200),
}

const RENTAL_RANGE_CONFIG = {
  label: "Månedlig ydelse",
  fieldName: "rentalRange",
  min: 500,
  max: 20000,
  formatValue: plus(kr, 20000),
}

const PRICE_RANGE_CONFIG = {
  label: "Pris",
  min: 0,
  max: 5000000,
  minDistance: 500,
  formatValue: plus(kr, 5000000),
}

const ROOMS_RANGE_CONFIG = {
  label: "Værelser",
  min: 1,
  max: 5,
  minDistance: 1,
  formatValue: plus((val) => val, 5),
}

const AdvancedSearchSection = ({ rentalRange, changeRentalRange, toggleAdvanced, isOpen }) => {
  return (
    <div className="row">
      <div className="col-s-12">
        <div className="search__advanced">
          <svg className="icon lightgreen icon--bordered-arrow" onClick={toggleAdvanced}>
            <use xlinkHref="#icon-arrow"></use>
          </svg>
          Avanceret søgning
        </div>
        <div className={isOpen ? "open" : ""} style={!isOpen ? { display: "none" } : null}>
          <div className="col-s-12 col-m-6 col-l-6">
            <div className="search__input-field">
              <div className="input-field__range">
                <RangeSlider {...RENTAL_RANGE_CONFIG} value={rentalRange} onChange={changeRentalRange} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const SearchSection = ({ search, actions, showAdvanced = true, to = null }) => {
  const { streetPostalCity, sizeRange, priceRange, roomsRange } = search
  const onChangeRange = (fieldName) => (value) => actions.changeRange(fieldName, value)
  const inputClassName = streetPostalCity.length > 0 ? "active" : ""
  const onChangeStreetPostalCity = (e) => {
    actions.changeStreetPostalCity(e.target.value)
  }
  const submitButton =
    to === null ? (
      <button className="button search__submit" type="submit" onClick={actions.initSearch}>
        Søg
      </button>
    ) : (
      <Link to={to}>
        <button className="button search__submit" type="submit" onClick={actions.initSearch}>
          Søg
        </button>
      </Link>
    )

  return (
    <section className="search " id="front-search">
      <div className="container">
        <div className="row bordered-columns">
          <div className="col-s-12 col-m-6 col-l-3">
            <div className="search__input-field">
              <div className="input-field__text input-field--text-bordered">
                <input
                  type="text"
                  value={streetPostalCity}
                  onChange={onChangeStreetPostalCity}
                  className={inputClassName}
                />
                <label htmlFor="input">Indtast vej, postnr eller by</label>
              </div>
            </div>
          </div>
          <div className="col-s-12 col-m-6 col-l-2">
            <div className="search__input-field">
              <div className="input-field__range">
                <RangeSlider {...SIZE_RANGE_CONFIG} value={sizeRange} onChange={onChangeRange("sizeRange")} />
              </div>
            </div>
          </div>
          <div className="col-s-12 col-m-6 col-l-3">
            <div className="search__input-field">
              <div className="input-field__range">
                <RangeSlider {...PRICE_RANGE_CONFIG} value={priceRange} onChange={actions.changePriceRange} />
              </div>
            </div>
          </div>
          <div className="col-s-12 col-m-6 col-l-2">
            <div className="search__input-field">
              <div className="input-field__range">
                <RangeSlider {...ROOMS_RANGE_CONFIG} value={roomsRange} onChange={onChangeRange("roomsRange")} />
              </div>
            </div>
          </div>
          <div className="col-s-12 col-m-12 col-l-2">
            <div className="search__input-field">{submitButton}</div>
          </div>
        </div>
        {showAdvanced ? (
          <AdvancedSearchSection
            rentalRange={search.rentalRange}
            isOpen={search.advancedIsOpen}
            toggleAdvanced={actions.toggleAdvanced}
            changeRentalRange={actions.changeRentalRange}
          />
        ) : null}
      </div>
    </section>
  )
}

SearchSection.displayName = "SearchSection"
SearchSection.propTypes = {}
SearchSection.defaultProps = {}

const mapStateToProps = (state) => {
  return {
    search: state.search,
  }
}

const mapDispatchToActions = (dispatch) => {
  return {
    actions: bindActionCreators(searchActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(SearchSection)
