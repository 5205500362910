import React from "react"
import HeroSection from "./HeroSection"
import SellCardsSection from "./SellCardsSection"
import ImageFullSection from "../../shared/ImageFullSection"
import ImageHalfSection from "./ImageHalfSection"
// import ALSection from "./ALSection"
import SearchForm from "../../shared/forms/SearchForm"
import QuoteSection from "./QuoteSection"
import CaseListWithData from "./CaseListWithData"
import { URLS } from "urls"
import { connect } from "react-redux"

const HomePage = () => {
  return (
    <div className="home animate--down">
      <HeroSection />
      <SearchForm to={URLS.BUY.FRONT} showAdvanced={false} />
      <ImageFullSection />
      <SellCardsSection />
      <CaseListWithData />
      <ImageHalfSection />
      {/* <ALSection/> */}
      <QuoteSection />
    </div>
  )
}

HomePage.displayName = "HomePage"
HomePage.propTypes = {}
HomePage.defaultProps = {}

const mapStateToProps = (state) => {
  return {
    app: state.app,
  }
}

export default connect(mapStateToProps)(HomePage)
